import { Storage } from "aws-amplify";
import config from "../config";

export async function s3Upload(file) {

    let filename = `${Date.now()}-${file.name}`;

    const stored = await Storage.vault.put(filename, file, {
        contentType: file.type
    });

    return stored.key;
}

//Configure Storage with S3 bucket information
export async function SetS3Config(bucket, level){
    Storage.configure({
        bucket: bucket,
        level: level,
        region: config.s3.REGION,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    });
}