const dev = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "dev-notes-app-notesuploadbucket"
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://elyy9n5nvd.execute-api.ap-southeast-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_7cRLojBVK",
        APP_CLIENT_ID: "55788150gb3tsgpskh7dvhkdtb",
        IDENTITY_POOL_ID: "ap-southeast-2:26beb2f2-039a-492c-a16d-237b3eeec8d0"
    },
    STRIPE_KEY: "pk_test_ypBCm87fPuwQogRCAn4sisxn003XWERZPF",
    PAPERCUT:{
        BUCKET: "dev-printcutuploadbucket"
    }
};

const prod = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "prod-notes-app-notesuploadbucket"
    },
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://0x383htcvl.execute-api.ap-southeast-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_5GqFpqeAq",
        APP_CLIENT_ID: "1jha60sk1bj9guu4milkg6pmqc",
        IDENTITY_POOL_ID: "ap-southeast-2:42ee0199-5eea-4d18-b717-7e019f7ec49d"
    },
    STRIPE_KEY: "pk_test_ypBCm87fPuwQogRCAn4sisxn003XWERZPF"

}

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
