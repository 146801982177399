import React, { useState } from "react";
//import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Auth } from "aws-amplify";
//import "./Login.css";
import LoaderButton from "../components/LoaderButton";
import { useFormFields} from "../libs/hooksLib";

export default function Login(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
    });

    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.signIn(fields.email, fields.password);
            props.userHasAuthenticated(true);

            Auth.currentAuthenticatedUser({
                bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
            }).then(user => {
                props.setMenuOptions(menuOptions => menuOptions.concat(user.signInUserSession.accessToken.payload["cognito:groups"]));
            }).catch(err => console.log(err));

        } catch (e) {
            alert(e.message);
            setIsLoading(false);
        }
    }

    return (
        <section id="login" data-animate="bounceIn" className="bg-gradient animated fadeInDown">
            <div className="container">
                <header className="text-center">
                    <h2 className="titleOrange">Login</h2>
                </header>
                <div className="row">
                    <div className="loginForm">
                        <form onSubmit={handleSubmit}>

                            <div className="form-group row">
                                <label htmlFor="email" className="col-md-4 col-form-label">Email *</label>
                                <div className="col-md-8">
                                    <input type="text" name="email" value={fields.email} id="email" onChange={handleFieldChange} required="required" className="form-control" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="password" className="col-md-4 col-form-label">Password *</label>
                                <div className="col-md-8">
                                    <input type="password" name="password" value={fields.password} id="password" onChange={handleFieldChange} required="required" className="form-control" />
                                </div>
                            </div>

                            <LoaderButton
                                block
                                type="submit"
                                bsSize="large"
                                className="btn-outline-primary"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                            >
                                Login
                            </LoaderButton>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}