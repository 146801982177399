import React, {useRef, useState} from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import { API } from "aws-amplify";
import "./papercut.css"
import { Grid } from 'react-bootstrap';
import {useFormFields} from "../../libs/hooksLib";

var createReactClass = require('create-react-class');

export default function AddAccount(props) {
    const file = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [accountFileUploadShow, setAccountFileUploadShow] = useState(false);
    const [accountTextBoxShow, setAccountTextBoxShow] = useState(true);
    const [fields, handleFieldChange] = useFormFields({
        newAccountName: "",
    });

    let fileReader;
    let arr = [];
    let checkValue = "single";

    function validateTextInputForm() {
        return fields.newAccountName.length > 0;
    }

    function handleFileChange(event) {
        file.current = event.target.files[0];
    }

    function onValueChange(event){
        checkValue = event.target.value
        if(checkValue === "file"){
            return [setAccountTextBoxShow(false), setAccountFileUploadShow(true)];
        } else if (checkValue === "single") {
            return [setAccountTextBoxShow(true), setAccountFileUploadShow(false)];
        }
    }

    function handleFileRead() {
        const content = fileReader.result;
        let lines = content.split('\n');
        lines.map(function(item) {
            var tabs = item.split(',');
            let myAccountName = tabs[0];
            if(myAccountName !== null && myAccountName !== '') {
                arr.push(myAccountName);
            }
        });
    };

    async function handleFileSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            if (file.current && file.current.size < config.MAX_ATTACHMENT_SIZE) {
                fileReader = new FileReader();
                fileReader.onload = async function (e) {
                    handleFileRead();
                    for (let i = 0, len = arr.length; i < len; i++) {
                        const content = arr[i];
                        createAccount({content});
                    }
                }
                fileReader.readAsText(file.current);
            }
            props.history.push("/paperCut/home");
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    }

    async function handleTextSubmit(event) {
        event.preventDefault()
        setIsLoading(true);
        try {
            const content = fields.newAccountName;
            await createAccount({content});
            props.history.push("/paperCut/home");
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    }

    async function createAccount(account) {
        return API.post("notes", "/papercut/account", {
            body: account
        });
    }

    let AccountTextBox = createReactClass({
        render: function () {
            return (
                <form onSubmit={handleTextSubmit} className="accountForm">
                    <FormGroup controlId="newAccountName" bsSize="large">
                        <ControlLabel>Account Name</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={fields.newAccountName}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        bsStyle="primary"
                        isLoading={isLoading}
                        disabled={!validateTextInputForm()}
                    >Add Account
                    </LoaderButton>
                </form>
            )
        }
    })

    let AccountFileUpload = createReactClass({
        render: function () {
            return (
                <form onSubmit={handleFileSubmit} className="accountForm">
                    <FormGroup controlId="file" className="accountFileInput">
                        <FormControl onChange={handleFileChange} type="file" accept=".csv" />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        bsStyle="primary"
                        isLoading={isLoading}
                    >Upload Account File
                    </LoaderButton>
                </form>
            )
        }
    })

    return (
        <section id="newNote" className="contact-section contact">
            <Grid>
                <header className="text-center">
                    <h2 className="title">Add New Account</h2>
                </header>
                 <div className="new-account-container">
                     <div className="radio">
                         <label>
                             <input
                                 type="radio"
                                 value="single"
                                 name="add-account-type"
                                 onChange={onValueChange}
                                 checked={ accountTextBoxShow}
                             />
                             By Text input
                         </label>
                     </div>
                     <div className="radio">
                         <label>
                             <input
                                 type="radio"
                                 value="file"
                                 name="add-account-type"
                                 onChange={onValueChange}
                                 checked={ accountFileUploadShow}
                             />
                             Add by File (CSV Only)
                         </label>
                     </div>
                     { accountTextBoxShow ? <AccountTextBox /> : <AccountFileUpload />  }
                 </div>
            </Grid>
        </section>
    );
}
