import React, {useEffect, useState} from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./papercut.css"
import { Grid } from 'react-bootstrap';
import Select from 'react-select';

var createReactClass = require('create-react-class');

export default function DeleteUser(props) {
    //const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [users, setUsers] = useState([]);
    const [sortedUsers, setSortedUsers] = useState([]);

    let optionsArray = [];

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated) {
                return;
            }

            let tempVar = [];
            try {
                let users = await loadUsers();
                users = sortByKey(users, 'userName');
                users.forEach(function (user) {
                    tempVar.push({'label' : user.userName, 'value' : user.userId});
                });
                setSortedUsers(tempVar);
                setUsers(users);
            } catch (e) {
                alert(e);
            }
            //setIsLoading(false);
        }

        onLoad();
    }, [props.isAuthenticated]);

    function sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    function loadUsers() {
        return API.get("notes", "/papercut/user");
    }

    function handleChange(e) {
        let options = JSON.stringify(e);
        optionsArray = [];
        JSON.parse(options, (key, value) => {
            if(key === "value") {
                optionsArray.push(value);
            }
        });
    }

    async function handleDeleteUser(event) {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete these users?"
        );

        if (!confirmed) {
            return;
        }

        setIsDeleting(true);

        try {
            optionsArray.forEach(element => deleteUser(element));
            props.history.push("/paperCut/home");
        } catch (e) {
            alert(e);
            setIsDeleting(false);
        }
    }

    function deleteUser(userToRemove) {
        return API.del("notes", `/papercut/user/${userToRemove}`);
    }

    let UserListBox = createReactClass({
        render: function () {
            return (
                <form onSubmit={handleDeleteUser} className="userForm">
                    <FormGroup controlId="userRemove" bsSize="large">
                        <ControlLabel>Users:</ControlLabel>
                        <Select
                            isSearchable
                            isMulti
                            options={sortedUsers}
                            onChange={handleChange} />
                    </FormGroup>
                    <LoaderButton
                        block
                        bsSize="large"
                        bsStyle="danger"
                        onClick={handleDeleteUser}
                        isLoading={isDeleting}
                    >
                        Delete Selected User
                    </LoaderButton>
                </form>
            )
        }
    })

    return (
        <section id="newNote" className="contact-section contact">
            <Grid>
                <header className="text-center">
                    <h2 className="title">Existing Users</h2>
                </header>
                <div className="new-user-container">
                     <UserListBox />
                </div>
            </Grid>
        </section>
    );
}
