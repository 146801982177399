import React from "react";
import "./Services.css"

class Services extends React.Component {
    render() {
        return (
            <section id="services" className="bg-gradient services">
                <div className="container">
                    <header className="text-center">
                        <h2 data-animate="fadeInDown" className="titleOrange animated fadeInDown">Services</h2>
                    </header>
                    <div className="row services text-center">
                        <div data-animate="fadeInUp" className="col-lg-4 animated fadeInUp">
                            <div className="icon"><i className="fa fa-search"></i></div>
                            <h3 className="heading mb-3 text-400">Consulting</h3>
                            <p className="text-left description">I offer a variety of web development consulting services,
                                from creating responsive web-based solutions and mobile friendly website designs, to building
                                custom ecommerce and intranet experiences using the latest and proven web technologies. Whatever
                                your project requires, I have the tools and experience to ensure that your goals are not only met,
                                but exceeded.</p>
                        </div>
                        <div data-animate="fadeInUp" className="col-lg-4 animated fadeInUp">
                            <div className="icon"><i className="fa fa-html5"></i></div>
                            <h3 className="heading mb-3 text-400">Web Development</h3>
                            <p className="text-left description">I'm capable of composing high-end web solutions, web applications,
                                and web portal development. With years of experience I can take any concept to reality by
                                developing standard and customized web solutions that meet specific business goals. I can integrate
                                any type of web service and API without affecting the current site performance and end-user experience.</p>
                        </div>
                        <div data-animate="fadeInUp" className="col-lg-4 animated fadeInUp">
                            <div className="icon"><i className="fa fa-tachometer"></i></div>
                            <h3 className="heading mb-3 text-400">Cloud Solutions</h3>
                            <p className="text-left description">Flexibility and scalability are the future of businesses. I can help enterprises build
                                Cloud-native applications from scratch as well as optimising/migrating existing applications to Cloud-native platforms. I
                                combine mobile-first design techniques with rich database functionality to create high-performance enterprise cloud apps.
                                I can deploy cloud apps in public, private or hybrid environments.</p>
                        </div>
                    </div>
                    <hr data-animate="fadeInUp" className="animated fadeInUp"/>
                    <div data-animate="fadeInUp" className="text-center animated fadeInUp">
                        <p className="lead">Would you like to know more or just discuss something?</p>
                        <p><a href="/contact" className="btn btn-outline-light link-scroll">Contact me</a></p>
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;