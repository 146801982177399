import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import {Nav, Navbar, NavDropdown, NavItem} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import { Auth } from "aws-amplify";
//import AuthenticatedMenuItems from "./containers/AuthenticatedMenuItems";

function App(props) {

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        }
        catch(e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
        props.history.push("/login");
    }

    return (
        !isAuthenticating &&
        <div className="App">
            <header className="container">
                <Navbar fluid collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link to="/">Kevin Portlock</Link>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            <LinkContainer to="/home">
                                <NavItem>Home</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/about">
                                <NavItem>About</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/services">
                                <NavItem>Services</NavItem>
                            </LinkContainer>
                            <LinkContainer to="/contact">
                                <NavItem>Contact</NavItem>
                            </LinkContainer>
                            {isAuthenticated ? (
                                <>
                                    <NavDropdown title="Client Area" id="clientAreaMenu">
                                        <LinkContainer to="/notes/home">
                                            <NavItem>Notes App3</NavItem>
                                        </LinkContainer>
                                        <LinkContainer to="/paperCut/home">
                                            <NavItem>PaperCut</NavItem>
                                        </LinkContainer>
                                    </NavDropdown>
                                    <LinkContainer to="/ecommerce">
                                        <NavItem>Ecommerce</NavItem>
                                    </LinkContainer>
                                    <NavItem onClick={handleLogout}>Logout</NavItem>
                                </>
                            ) : (
                                <>
                                    <LinkContainer to="/signup">
                                        <NavItem>Signup</NavItem>
                                    </LinkContainer>
                                    <LinkContainer to="/login">
                                        <NavItem>Login</NavItem>
                                    </LinkContainer>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
            <Routes appProps={{ isAuthenticated, userHasAuthenticated, setMenuOptions}} />
        </div>
    );
}

export default withRouter(App);