import React from "react";

class Contact extends React.Component {
    render() {
        return (
            <section id="contact" data-animate="bounceIn" className="bg-gradient animated bounceIn">
                <div className="container">
                    <header className="text-center">
                        <h2 className="titleOrange">Contact me</h2>
                    </header>
                    <div className="row text-center">
                        <div className="contactForm">
                            <form id="contact-form" method="post" action="">
                                <div className="messages"></div>
                                <div className="controls">
                                    <div className="form-group row">
                                        <label htmlFor="name" className="col-md-4 col-form-label">Your firstname *</label>
                                        <div className="col-md-8">
                                            <input type="text" name="name"  id="name" required="required" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="surname" className="col-md-4 col-form-label">Your lastname *</label>
                                        <div className="col-md-8">
                                            <input type="text" name="surname" id="surname" required="required" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="email" className="col-md-4 col-form-label">Your email *</label>
                                        <div className="col-md-8">
                                            <input type="text" name="email" id="email" required="required" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="phone" className="col-md-4 col-form-label">Your phone *</label>
                                        <div className="col-md-8">
                                            <input type="text" name="phone" id="phone" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="message" className="col-md-4 col-form-label">Message for me *</label>
                                        <div className="col-md-8">
                                            <textarea name="message" id="message" rows="4" required="required" className="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="btn btn-outline-light">Send message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;