import React, {useRef, useState} from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import { API } from "aws-amplify";
import "./papercut.css"
import { Grid } from 'react-bootstrap';
import {useFormFields} from "../../libs/hooksLib";

var createReactClass = require('create-react-class');

export default function AddUsers(props) {
    const file = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [userFileUploadShow, setUserFileUploadShow] = useState(false);
    const [userTextBoxShow, setUserTextBoxShow] = useState(true);
    const [fields, handleFieldChange] = useFormFields({
        newUserName: "",
    });

    let fileReader;
    let arr = [];
    let checkValue = "single";
    var userNamesNotToInclude = ["", "Username", "", null];

    function validateTextInputForm() {
        return fields.newUserName.length > 0;
    }

    function validateFileUploadForm() {
        //return content.length > 0;
    }

    function handleFileChange(event) {
        file.current = event.target.files[0];
    }

    function onValueChange(event){
        checkValue = event.target.value
        if(checkValue === "file"){
            return [setUserTextBoxShow(false), setUserFileUploadShow(true)];
        } else if (checkValue === "single") {
            return [setUserTextBoxShow(true), setUserFileUploadShow(false)];
        }
    }

    function validUserName(uname){
        if( (userNamesNotToInclude.indexOf(uname) < 0) && (!uname.startsWith("#")) ) {
            return true;
        }
        return false;
    }

    function handleFileRead() {
        const content = fileReader.result;
        let lines = content.split('\n');
        lines.map(function(item) {
            let tabs = item.split(',');
            let myUserName = tabs[0];
            if(validUserName(myUserName)) {
                arr.push(myUserName);
            }
        });
    };


    async function handleFileSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            if (file.current && file.current.size < config.MAX_ATTACHMENT_SIZE) {
                fileReader = new FileReader();
                fileReader.onload = async function (e) {
                    handleFileRead();
                    for (let i = 0, len = arr.length; i < len; i++) {
                        const content = arr[i];
                        console.log("content: " + content)
                        createUser({content});
                    }
                }
                fileReader.readAsText(file.current);
            }
            props.history.push("/paperCut/home");
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    }

    async function handleTextSubmit(event) {
        event.preventDefault()
        setIsLoading(true);
        try {
            const content = fields.newUserName;
            await createUser({content});
            props.history.push("/paperCut/home");
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    }

    async function createUser(user) {
        return API.post("notes", "/papercut/user", {
            body: user
        });
    }

    let UserTextBox = createReactClass({
        render: function () {
            return (
                <form onSubmit={handleTextSubmit} className="userForm">
                    <FormGroup controlId="newUserName" bsSize="large">
                        <ControlLabel>User Name</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={fields.newUserName}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        bsStyle="primary"
                        isLoading={isLoading}
                        disabled={!validateTextInputForm()}
                    >Add User
                    </LoaderButton>
                </form>
            )
        }
    })

    let UserFileUpload = createReactClass({
        render: function () {
            return (
                <form onSubmit={handleFileSubmit} className="userForm">
                    <FormGroup controlId="file" className="userFileInput">
                        <FormControl onChange={handleFileChange} type="file" accept=".csv"/>
                    </FormGroup>

                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        bsStyle="primary"
                        isLoading={isLoading}
                    >Upload User File
                    </LoaderButton>
                </form>
            )
        }
    })

    return (
        <section id="newNote" className="contact-section contact">
            <Grid>
                <header className="text-center">
                    <h2 className="title">Add New User</h2>
                </header>
                <div className="new-user-container">
                    <div className="radio">
                        <label>
                            <input
                                type="radio"
                                value="single"
                                name="add-user-type"
                                onChange={onValueChange}
                                checked={ userTextBoxShow}
                            />
                            By Text input
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input
                                type="radio"
                                value="file"
                                name="add-user-type"
                                onChange={onValueChange}
                                checked={ userFileUploadShow}
                            />
                            Add by File (CSV Only)
                        </label>
                    </div>
                    { userTextBoxShow ? <UserTextBox /> : <UserFileUpload />  }
                </div>
            </Grid>
        </section>
    );
}
