import React from "react";
import { Route, Switch } from "react-router-dom";
//import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import About from "./containers/about";
import Services from "./containers/Services";
import Contact from "./containers/contact";
import Login from "./containers/Login";
import Signup from "./containers/Signup";

import NoteHome from "./containers/notes-app/Home";
import NoteNew from "./containers/notes-app/NewNote";
import Notes from "./containers/notes-app/Notes";
import Ecommerce from "./containers/notes-app/Ecommerce";

import AccountHome from "./containers/papercut/AccountHome";
import AddAccounts from "./containers/papercut/AddAccounts";
import DeleteAccounts from "./containers/papercut/DeleteAccounts";
import AddUsers from "./containers/papercut/AddUsers";
import DeleteUser from "./containers/papercut/DeleteUser";


import NotFound from "./containers/NotFound";

export default function Routes({ appProps }) {
    return (
        <Switch>

            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/services" exact component={Services} />
            <Route path="/contact" exact component={Contact} />

            <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
            <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />

            <AuthenticatedRoute path="/ecommerce" exact component={Ecommerce} appProps={appProps} />

            <AuthenticatedRoute path="/notes/home" exact component={NoteHome} appProps={appProps} />
            <AuthenticatedRoute path="/notes/new" exact component={NoteNew} appProps={appProps} />
            <AuthenticatedRoute path="/notes/:id" exact component={Notes} appProps={appProps} />

            <AuthenticatedRoute path="/paperCut/home" exact component={AccountHome} appProps={appProps} />
            <AuthenticatedRoute path="/papercut/add-account" exact component={AddAccounts} appProps={appProps} />
            <AuthenticatedRoute path="/papercut/remove-account" exact component={DeleteAccounts} appProps={appProps} />
            <AuthenticatedRoute path="/papercut/add-user" exact component={AddUsers} appProps={appProps} />
            <AuthenticatedRoute path="/papercut/remove-user" exact component={DeleteUser} appProps={appProps} />

            { /* Finally, catch all unmatched routes */ }
            <Route component={NotFound} />
        </Switch>
    );
}