import React, {useEffect, useState} from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./papercut.css"
import { Grid } from 'react-bootstrap';
import Select from 'react-select';

var createReactClass = require('create-react-class');

export default function DeleteAccount(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [sortedAccounts, setSortedAccounts] = useState([]);

    let optionsArray = [];

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated) {
                return;
            }

            let tempVar = [];
            try {
                let accounts = await loadAccounts();
                accounts = sortByKey(accounts, 'accountName');
                accounts.forEach(function (account) {
                    tempVar.push({'label' : account.accountName, 'value' : account.accountId});
                });
                setSortedAccounts(tempVar);
                setAccounts(accounts);
            } catch (e) {
                alert(e);
            }

            setIsLoading(false);
        }

        onLoad();
    }, [props.isAuthenticated]);

    function sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    function loadAccounts() {
        return API.get("notes", "/papercut/account");
    }

    function handleChange(e) {
        let options = JSON.stringify(e);
        optionsArray = [];
        JSON.parse(options, (key, value) => {
            if(key === "value") {
                optionsArray.push(value);
            }
        });
    }

    async function handleDeleteAccount(event) {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete these accounts?"
        );

        if (!confirmed) {
            return;
        }

        setIsDeleting(true);

        try {
            optionsArray.forEach(element => deleteAccount(element));
            props.history.push("/paperCut/home");
        } catch (e) {
            alert(e);
            setIsDeleting(false);
        }
    }

    function deleteAccount(accountToRemove) {
        return API.del("notes", `/papercut/account/${accountToRemove}`);
    }

    let AccountListBox = createReactClass({
        render: function () {
            return (
                <form onSubmit={handleDeleteAccount} className="accountForm">
                    <FormGroup controlId="accountRemove" bsSize="large">
                        <ControlLabel>Accounts:</ControlLabel>
                        <Select
                            isSearchable
                            isMulti
                            options={sortedAccounts}
                            onChange={handleChange} />
                    </FormGroup>
                    <LoaderButton
                        block
                        bsSize="large"
                        bsStyle="danger"
                        onClick={handleDeleteAccount}
                        isLoading={isDeleting}
                    >
                        Delete Selected Account
                    </LoaderButton>
                </form>
            )
        }
    })

    return (
        <section id="newNote" className="contact-section contact">
            <Grid>
                <header className="text-center">
                    <h2 className="title">Existing Accounts</h2>
                </header>
                <div className="new-account-container">
                     <AccountListBox />
                </div>
            </Grid>
        </section>
    );
}
