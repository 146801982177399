import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {Col, Grid, Nav, NavItem, Row} from "react-bootstrap";

export default function AccountHome() {

    return (
        <section id="paperCutHome">
            <Grid>
                <header className="text-center">
                    <h2 data-animate="fadeInDown" className="pageTitle">PaperCut Menu</h2>
                </header>
                <Row>
                    <Col sm={4}>
                        <Nav>
                            <LinkContainer to={'/papercut/add-account'} exact>
                                <NavItem eventKey={1}>
                                    Add Accounts
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to={'/papercut/remove-account'} exact>
                                <NavItem eventKey={2}>
                                    Remove Account
                                </NavItem>
                            </LinkContainer>
                        </Nav>
                    </Col>
                    <Col sm={4}>
                        <Nav>
                            <LinkContainer to={'/papercut/add-user'} exact>
                                <NavItem eventKey={3}>
                                    Add User
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to={'/papercut/remove-user'} exact>
                                <NavItem eventKey={4}>
                                    Remove User
                                </NavItem>
                            </LinkContainer>
                        </Nav>
                    </Col>
                    <Col sm={4}>
                        <Nav>
                            <LinkContainer to={'/papercut/link-users'}>
                                <NavItem eventKey={3}>
                                    Link Users to Accounts
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to={'/papercut/output-file'}>
                                <NavItem eventKey={3}>
                                    Output Linked Users to Accounts
                                </NavItem>
                            </LinkContainer>
                        </Nav>
                    </Col>
                </Row>
            </Grid>
        </section>
    );
}