import React, { useState } from "react";
import { API } from "aws-amplify";
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingForm from "../../components/BillingForm";
import config from "../../config";

export default function Ecommerce(props) {
    const [isLoading, setIsLoading] = useState(false);

    function billUser(details) {
        return API.post("notes", "/billing", {
            body: details
        });
    }

    async function handleFormSubmit(storage, { token, error }) {
        if (error) {
            alert(error);
            return;
        }

        setIsLoading(true);

        try {
            await billUser({
                storage,
                source: token.id
            });

            alert("Your card has been charged successfully!");
            props.history.push("/notes/home");
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    }

    return (
        <section id="ecom" className="bg-gradient">
            <div className="container">
                <header className="text-center">
                    <h2 className="titleOrange">eCommerce</h2>
                </header>
                    <div className="ecomForm">
                        <StripeProvider apiKey={config.STRIPE_KEY}>
                            <Elements>
                                <BillingForm
                                    isLoading={isLoading}
                                    onSubmit={handleFormSubmit}
                                />
                            </Elements>
                        </StripeProvider>
                    </div>
                </div>

        </section>
    );
}